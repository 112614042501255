import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  confirm_email: Yup.string()
    .required("Confirm email is required")
    .email("Confirm email must be a valid email address")
    .oneOf([Yup.ref("email"), null], "Emails must match"),
  program: Yup.string().required("Program is required"),
  source: Yup.string().required("This field is required"),
  phoneNumber: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Phone is required"),
  country: Yup.string().required("Country is required"),
  province: Yup.string().required("Province is required"),
  city: Yup.string().required("City is required"),
  referral: Yup.string(),
});

export const init = {
  firstName: "",
  lastName: "",
  email: "",
  confirm_email: "",
  companyName: "",
  phoneNumber: "",
  couponCode: "",
  program: "",
  country: "",
  source: "",
  province: "",
  city: "",
  route: "",
};

export const specialProgramData = {
  business_id: 23,
  currency: "USD",
  html: "",
  id: 24,
  logo: "https://s3.amazonaws.com/teachnow.io/TeachNow-Dev/20231230131704-20220127130808-logo-white.png",
  price1: "500",
  price2: "1000",
  price3: "1000",
  status: true,
  template: "pricing",
  title: "Blackforce Inc. (Canada)",
  url: "canada",
  user_id: "02b4957e-e5ab-4ad9-9de5-cf439c8d29f7",
};
