import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Head } from "../components/Coach";
import { verifyCoupon, checkCoupon, findTaxrates } from "./_redux/pagesCrud";
import swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createLead } from "./_redux/pagesCrud";
import countries from "../../../data/countries.json";
import Navigation from "./Nav";
import Home from "./Home";
import TermsCondition from "./TermsCondition";
import { toast } from "react-toastify";
import "./components/pricing.css";
import { useQueryParams } from "../../contexts/QueryParamsContext";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  confirm_email: Yup.string()
    .required("Confirm email is required")
    .email("Confirm email must be a valid email address")
    .oneOf([Yup.ref("email"), null], "Emails must match"),
  // program: Yup.string().required("Program is required"),
  phoneNumber: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Phone is required"),
  country: Yup.string().required("Country is required"),
  province: Yup.string().required("Province is required"),
  city: Yup.string().required("City is required"),
  // referral: Yup.string(),
});

const associateData = {
  business_id: 23,
  currency: "USD",
  html: "",
  id: 15,
  logo: "https://s3.amazonaws.com/teachnow.io/TeachNow-Dev/20231230131704-20220127130808-logo-white.png",
  price1: "25",
  price2: "250",
  price3: "250",
  status: true,
  template: "pricing",
  title: "Blackforce Inc. (Canada)",
  url: "canada",
  user_id: "02b4957e-e5ab-4ad9-9de5-cf439c8d29f7",
};
export default function Pricing() {
  const { queryParams } = useQueryParams();
  const pageLocation = useLocation();
  let cLocation = pageLocation.pathname.substring(1).split("/")[1];

  const [professionalPrice, setProfessionalPrice] = useState(false);
  const [page, setPage] = useState();
  const [price, setPrice] = useState();
  const [amountToPay, setAmountToPay] = useState();
  const [couponValue, setCouponValue] = useState(0);
  const [priceData, setPriceData] = useState();
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [referral, setReferral] = useState(false);
  const [coup, setCoup] = useState();
  const [states, setStates] = useState();
  const [halfPrice, setHalfPrice] = useState(false);
  const [sell, setSell] = useState();
  const [query_coupon, setQueryCoupon] = useState(null);
  const [d_program, setDProgram] = useState("Blackforce Associate - Monthly");

  const init = {
    firstName: "",
    lastName: "",
    email: "",
    confirm_email: "",
    companyName: "",
    phoneNumber: "",
    couponCode: "",
    program: d_program,
    country: "",
    province: "",
    city: "",
    route: "",
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: init,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.companyName = page.title;
      values.price = price;
      values.amount_to_pay = amountToPay;
      values.page_id = page.id;
      values.price_type = priceData;
      values.half_price = halfPrice;
      values.professionalPrice = professionalPrice;
      values.isAssociate = true;
      values.program = d_program;
      values.installments =
        halfPrice && !professionalPrice
          ? 2
          : !halfPrice && professionalPrice
          ? 4
          : 1;

      if (selectedState && !selectedState.inclusive)
        values.tax_rate_id = selectedState.tax_rate_id;
      // Number(amountToPay) +
      // (selectedState.percentage * Number(amountToPay)) / 100
      setLoading(true);
      createLead(values)
        .then((result) => {
          if (result.status !== 200) throw result;
          resetForm();
          document.getElementById("closeFormModal").click();
          swal.fire(
            "",
            `Your registration was successful, an email will be sent to you shortly`,
            "success"
          );
          if (result.data.data?.payment_url)
            window.location.href = result.data.data?.payment_url;
          setLoading(false);
          setCouponValue(0);
        })
        .catch((e) => {
          swal.fire(
            "Oops",
            e.response?.data?.message || "Error uploading data",
            "error"
          );
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    if (formik.values.country)
      findTaxrates({
        filter: {
          status: true,
          country: formik.values.country,
          business_id: associateData.business_id, //
        },
      })
        .then((res) => {
          setStates(res.data?.data?.entities);
          formik.values.province = "";
        })
        .catch((e) => setStates([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);
  const selectedState = useMemo(
    () => states?.find((s) => s.state === formik.values.province),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.province]
  );

  useEffect(() => {
    if (page) {
      for (let a = 1; a < 3 + 1; a++) {
        if (document.getElementById("price" + a) !== null) {
          let pp = Number(page["price" + a] || 0);
          if (halfPrice && !professionalPrice && pp > 1) pp /= 2;
          if (!halfPrice && professionalPrice && pp > 1) {
            // const element = document.getElementById("price3");
            const element = document.getElementById("price" + a);
            const _pp = (pp /= 4);
            element.innerHTML = _pp.toLocaleString("en-US", {
              style: "currency",
              currency: page?.currency || "USD",
              minimumFractionDigits: 0,
            });
          } else {
            const element = document.getElementById("price" + a);
            element.innerHTML = pp.toLocaleString("en-US", {
              style: "currency",
              currency: page?.currency || "USD",
              minimumFractionDigits: 0,
            });
          }
        }
      }
    }
  }, [page, referral, halfPrice, professionalPrice]);
  useEffect(() => {
    if (sell) {
      let pp = Number(page["price" + sell] || 0);
      setPrice(pp);
      setAmountToPay(
        referral && amountToPay && priceData === "price" + sell
          ? amountToPay
          : pp
      );
      setPriceData("price" + sell);
      setCouponValue(
        referral && couponValue && priceData === "price" + sell
          ? couponValue
          : 0
      );
      setAccepted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sell]);

  const verifyCouponCode = (pr, loud) => {
    if (formik.values.couponCode || query_coupon) {
      let coupon_value,
        new_price = pr || price,
        res;
      setLoading(true);
      verifyCoupon(
        {
          code: formik.values.couponCode || query_coupon,
          amount: new_price,
        },
        page.business_id
      )
        .then((result) => {
          if (result.status === 200) {
            loud && toast.success("Coupon applied!");
            res = result.data.data;
            coupon_value = res.coupon_value;
            if (res.coupon_type === "percentage")
              coupon_value = (res.coupon_value * new_price) / 100;
            new_price -= coupon_value;
            formik.setFieldValue("coupon_id", res.id);
            formik.setFieldValue("coupon_code", formik.values.couponCode);
          } else {
            toast.error(result.response.data.message);
            // swal.fire("Oops", result.response.data.message, "error");
          }
        })
        .catch((e) => {
          console.log(e);
          setCoup(res);
          setAmountToPay(new_price);
          setCouponValue(coupon_value);
        })
        .finally(() => {
          setCoup(res);
          setAmountToPay(new_price);
          setCouponValue(coupon_value);
          setLoading(false);
        });
    } else toast.warn("Please enter your referral code");
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  Head();
  useEffect(() => {
    setPage(associateData);
    document.getElementById("splashScreenButton").click();
  }, []);

  useEffect(() => {
    if (queryParams.code) {
      setQueryCoupon(queryParams.code); // Update state with the code
      formik.setFieldValue("couponCode", queryParams.code); // Update formik value
    }
  }, [queryParams.code]);
  // Memoize the callback function

  const getCouponCode = useCallback(() => {
    return query_coupon;
  }, [query_coupon]); // couponCode in dependency array

  return (
    <>
      {referral && (
        <>
          <Navigation page={page} />

          {/* <Home /> */}
          <Home
            setSell={setSell}
            page={page}
            amountToPay={amountToPay}
            currency={associateData.currency}
            halfPrice={halfPrice}
            professionalPrice={professionalPrice}
            setAccepted={setAccepted}
            setDProgram={setDProgram}
          />
        </>
      )}

      <TermsCondition
        accepted={accepted}
        formik={formik}
        priceData={priceData}
        setAccepted={setAccepted}
        getInputClasses={getInputClasses}
        cLocation={cLocation}
        countries={countries}
        states={states}
        amountToPay={amountToPay}
        couponValue={couponValue}
        verifyCouponCode={verifyCouponCode}
        price={price}
        page={page}
        coup={coup}
        selectedState={selectedState}
        loading={loading}
        getCouponCode={getCouponCode}
      />
      <button
        type="button"
        data-toggle="modal"
        data-target="#SplashScreenModal"
        id="splashScreenButton"
        hidden
      ></button>
      <div
        className="modal fade"
        id="SplashScreenModal"
        tabIndex="-1"
        aria-labelledby="SplashScreenModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        style={{ background: "#261f44!important" }}
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title m-auto" id="SplashScreenModalLabel">
                BlackForce Associate Program
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                hidden
                id="SplashScreenModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-5 welcome-note">
              <p>
                The Blackforce Associate Program is designed for professionals
                who want to experience our intensive COHORT program but don’t
                have the time to commit to a full four to six-month program.
              </p>
              <p>
                {" "}
                This program gives you access to the same high-quality training,
                allowing you to join our Master Classes, Lunch & Learn sessions,
                and gain access to all recorded videos.
              </p>

              <p>
                The Blackforce Associate Program provides you with Video Access
                & Certification Preparation Materials Only – enables you watch
                all past COHORT program recordings anytime, anywhere as well as
                prepare for your certification exams.
              </p>
              <p>
                {" "}
                You also get Live Access to join weekend Master Classes, Lunch &
                Learn sessions, and any special events in real time.
              </p>
              <p>
                At Blackforce, we have over a hundred videos spanning across
                different programs and courses, such as Salesforce Business
                Analysis, Data Analysis, Power BI, Cybersecurity including
                Governance Risk and Compliance, Incident Responder, Salesforce
                Administrator, Salesforce Marketing Cloud, Salesforce Sales
                Cloud, Salesforce Service Cloud, Salesforce Platform Developer,
                Structure Query Language, HR Interview Preparation Materials and
                many more…
              </p>

              <p>
                <b>
                  <span className="text-danger">
                    You can join the Blackforce Associate program for just $25
                    per month
                  </span>
                  . You can cancel this subscription at any time.{" "}
                </b>
              </p>

              <p>
                To proceed, please use the continue subscription below or if you
                need additional information or assistance in joining BlackForce
                or subscribing to our videos, contact us at{" "}
                <a href="mailto:support@blackforce.ca">support@blackforce.ca</a>
              </p>

              <p>
                <b>Thank you for supporting the Blackforce Community!</b> Your
                subscription helps us continue providing{" "}
                <b>
                  affordable, high-quality training that empowers and uplifts
                </b>{" "}
                our community.
              </p>

              <p>Best of luck in all your endeavours!</p>
              <div className="row mt-5">
                <center>
                  <div className="mb-3 col-md-8 col-12">
                    {/* <label
											htmlFor='firstName'
											className='form-label font-weight-bolder'
										>
											<h4>Registration for Cohort 5 is now closed</h4>
										</label> */}
                    <button
                      type="button"
                      className="btn btn-secondary ml-3"
                      onClick={() => {
                        document
                          .getElementById("SplashScreenModalClose")
                          .click();
                        setReferral(true);
                      }}
                    >
                      Continue Subscription
                    </button>

                    <div className="invalid-feedback">
                      {formik.errors["couponCode"]}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
