import React from "react";

function SplashModal({ formik, setReferral }) {
  return (
    <>
      <button
        type="button"
        data-toggle="modal"
        data-target="#SplashScreenModal"
        id="splashScreenButton"
        hidden
      ></button>
      <div
        className="modal fade"
        id="SplashScreenModal"
        tabIndex="-1"
        aria-labelledby="SplashScreenModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        style={{ background: "#261f44!important" }}
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title m-auto" id="SplashScreenModalLabel">
                BlackForce Special Programs Payment
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                hidden
                id="SplashScreenModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-5 mobile-font-size">
              <p>
                <b>Welcome to Blackforce!!</b>
              </p>
              <p>
                The Blackforce Special Programs consist of carefully selected
                courses designed to meet the demands of the current market,
                specifically targeting experienced professionals.
              </p>

              <p>
                These special programs offer established IT professionals the
                chance to engage with the Blackforce offerings, bypassing the
                typical intensive four-six months training and internship
                requirements.
              </p>

              <p>
                <b>Here is a list of the special programs for this COHORT 9:</b>
              </p>
              <ol>
                <li className="mb-2">
                  <b>
                    Sales Cloud, Service Cloud & Artificial Intelligence - March
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Artificial Intelligence & Integration Development - April
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Certified Business Analysis Professional (CBAP) with
                    Salesforce AI April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Data Analytics, Tableau & Microsoft Power BI with Salesforce
                    AI - April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Platform Developer with Salesforce AI - April
                    2025
                  </b>
                </li>
                {/* <li className="mb-2">
                  <b>
                    Cybersecurity Incident Responder with Salesforce Artificial
                    Intelligence - April 2025
                  </b>
                </li> */}
                <li className="mb-2">
                  <b>
                    SF. Administrator with On Demand Videos Sales and Service
                    Cloud - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Health Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Financial Services Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Nonprofit Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Marketing Cloud Administrator with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce DevOps Engineering with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Administrator with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Education Cloud with Salesforce Artificial
                    Intelligence - June 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Integration Architect and Artificial Intelligence
                    - June 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Quality Assurance Testing and Artificial
                    Intelligence - June 2025
                  </b>
                </li>
              </ol>
              {/* <p>We're happy to help.</p> */}
              <p>
                *A minimum of 10 students is required for a special program to
                proceed; otherwise, the class will be postponed until the next
                COHORT.
              </p>

              <p>Best of luck in all your endeavors!</p>
              <div className="row">
                <center>
                  <div className="mb-3 col-md-8 col-12">
                    {/* <label
											htmlFor='firstName'
											className='form-label font-weight-bolder'
										>
											<h4>Registration for Cohort 5 is now closed</h4>
										</label> */}
                    <button
                      type="button"
                      className="btn btn-secondary ml-3"
                      onClick={() => {
                        document
                          .getElementById("SplashScreenModalClose")
                          .click();
                        setReferral(true);
                      }}
                    >
                      Continue with Registration
                    </button>

                    <div className="invalid-feedback">
                      {formik.errors["couponCode"]}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SplashModal;
