import React from "react";

function OptionsModal({ setSell, setAccepted, setDProgram }) {
  return (
    <div
      className="modal fade"
      id="optionModal"
      tabIndex="-1"
      aria-labelledby="optionModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg  modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body ">
            {/*start join now*/}
            <section className="py-lg-4 py-xxl-4 py-4 bg-light">
              <div className="container">
                <div className="row">
                  <div className="text-center mb-lg-4 mb-3">
                    <h2 className="fw-bold mb-3">
                      Save Big with Annual Billing - 20% Off
                    </h2>
                    <p className="mb-0 px-lg-10 px-5">
                      Save 20% by choosing annual billing over monthly! Get 12
                      months of premium features for the price of 10. Hurry—this
                      limited-time offer won’t last!
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
                    <div className="row">
                      <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                        {/*card*/}
                        <div className="card">
                          {/*card-body*/}
                          <div className="card-body p-5">
                            {/*content*/}
                            <span className="text-danger text-uppercase font-weight-bold">
                              Monthly
                            </span>
                            <div className="d-flex align-items-center mt-5">
                              <h2>
                                $25{" "}
                                <span
                                  style={{
                                    fontSize: "14.6667px",
                                  }}
                                >
                                  /month
                                </span>
                              </h2>
                              {/* <span className="ms-1">(USD)</span> */}
                            </div>
                            <p className="mb-5">
                              Charges you monthly and you can cancel anytime you
                              want.{" "}
                              <span className="text-danger">
                                No Access to Special WhatsApp group
                              </span>
                            </p>

                            <div className="mt-5 d-grid">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-toggle="modal"
                                data-target="#formModal"
                                onClick={() => {
                                  setSell(1);
                                  // document.getElementById("pricing").click();
                                  setAccepted(true);
                                  setDProgram("Blackforce Associate - Monthly");
                                }}
                                id="button1"
                                disabled=""
                              >
                                Subscribe Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                        {/*card*/}
                        <div className="card">
                          {/*card body*/}
                          <div className="card-body p-5">
                            {/*content*/}
                            <span className="text-uppercase text-danger font-weight-bold">
                              Yearly
                            </span>
                            <div className="d-flex align-items-center mt-5">
                              <h2>
                                $250{" "}
                                <span
                                  style={{
                                    fontSize: "14.6667px",
                                  }}
                                >
                                  /year
                                </span>
                              </h2>
                              {/* <span className="ms-1">(USD)</span> */}
                            </div>
                            <p className="mb-0">
                              Charges you yearly and you can cancel anytime you
                              want.{" "}
                              <span className="text-danger">
                                Access to Special WhatsApp group
                              </span>
                            </p>

                            <div className="mt-5 d-grid">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-toggle="modal"
                                data-target="#formModal"
                                onClick={() => {
                                  setSell(2);
                                  // document.getElementById("pricing").click();
                                  setAccepted(true);
                                  setDProgram("Blackforce Associate - Yearly");
                                }}
                                id="button2"
                                disabled=""
                              >
                                Subscribe Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 text-center mt-3">
                        <p
                          className="text-danger"
                          style={{
                            color: "rgb(0, 0, 0)",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "14.6667px",
                            whiteSpaceCollapse: "preserve",
                          }}
                        >
                          Payments and non refundable and accounts are not
                          transferable. You can cancel at anytime.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end of join mow*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionsModal;
