import React from "react";
import { standardCourse } from "./programs";
import CourseTile from "./CourseTile";
import OptionsModal from "./OptionsModal";
import "./pricing.css";

export default function Pricing({ setSell, setAccepted, setDProgram }) {
  return (
    <>
      <div className="row" id="pricing">
        <div className="col-lg-2 col-md-2 col-12"></div>
        <div className="offset-lg-1 col-lg-6 col-md-6 col-12">
          <div className="card rounded-lg mb-6">
            <div className="card-body p-lg-6 p-md-3">
              <h4 className="mb-3 text-primary">Blackforce Associate</h4>

              <div className="pricing-container">
                {/* <span className="h1 d-flex flex-wrap align-items-center gap-2">
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#999",
                    }}
                    className="fs-lg-1 fs-md-2 fs-3"
                  >
                    $2000
                  </span>
                </span> */}
                <span className="fs-lg-1 fs-md-2 fs-3 font-weight-bold text-dark">
                  $25<span className="fs-sm-1">/month</span>{" "}
                  {/* <span className="">(USD)</span> */}
                </span>
                <div className="text-success font-weight-bolder small mt-1">
                  Save 20% when you subscribe for a yearly plan
                </div>
              </div>
            </div>

            <div className="card-body pb-6 pt-0 px-lg-6 px-md-3">
              <ul className="list-unstyled mobile-font-size">
                {standardCourse?.length > 0 &&
                  standardCourse.map((course, indx) => (
                    <CourseTile key={indx} course={course} />
                  ))}
              </ul>
              <button
                type="button"
                className="btn btn-primary btn-block mb-3 mt-6"
                data-toggle="modal"
                data-target="#optionModal"
                // id="button2"
                disabled=""
              >
                Subscribe Now
              </button>
              <div className="col-12 font-14">
                <p className="text-danger">
                  Payments are non refundable. Accounts cannot be transferred.
                  {/* All prices are in <b>USD</b> */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-12"></div>
      </div>
      <OptionsModal
        setSell={setSell}
        setAccepted={setAccepted}
        setDProgram={setDProgram}
      />
    </>
  );
}
