/**
 * Context for managing query parameters throughout the application.
 * This centralizes query parameter handling to avoid parameter loss during re-renders.
 */

import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Create context
const QueryParamsContext = createContext();

// Hook for components to consume the context
export const useQueryParams = () => {
  const context = useContext(QueryParamsContext);
  if (!context) {
    throw new Error("useQueryParams must be used within a QueryParamsProvider");
  }
  return context;
};

export const QueryParamsProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  // Parse query params from location.search
  const getParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return Object.fromEntries(searchParams.entries());
  };

  const [queryParams, setQueryParamsState] = useState(getParams());

  // Update queryParams state when the URL search changes
  useEffect(() => {
    setQueryParamsState(getParams());
  }, [location.search]);

  // Function to update a query parameter
  const setQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);

    if (value === null || value === undefined) {
      params.delete(key);
    } else {
      params.set(key, value);
    }

    // Update the URL without causing a full page navigation
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  // Function to update multiple query parameters at once
  const setQueryParams = (newParams) => {
    const params = new URLSearchParams(location.search);

    Object.entries(newParams).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  // Function to remove a query parameter
  const removeQueryParam = (key) => {
    const params = new URLSearchParams(location.search);
    params.delete(key);

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  // Function to clear all query parameters
  const clearQueryParams = () => {
    history.replace({
      pathname: location.pathname,
      search: "",
    });
  };

  const contextValue = {
    queryParams,
    setQueryParam,
    setQueryParams,
    removeQueryParam,
    clearQueryParams,
  };

  return (
    <QueryParamsContext.Provider value={contextValue}>
      {children}
    </QueryParamsContext.Provider>
  );
};
