/**
 * High level router with improved query parameter handling.
 * Uses React Router's query parameter management to ensure persistence
 * across component rerenders and easy access for child components.
 */

import React, { lazy, useEffect } from "react";
import {
  Redirect,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { QueryParamsProvider } from "./contexts/QueryParamsContext";
import ErrorPage from "./modules/Errors/ErrorPage";
import Logout from "./modules/Auth/pages/Logout";
import swal from "sweetalert2";
import Pages from "./modules/Associate/Main";
import SpecialPage from "./modules/SpecialProgram/Main";

const MyPages = lazy(() => import("./modules/MyPages/myPages"));
const SubscriptionPage = lazy(() =>
  import("./modules/Subscription/SubscriptionPage")
);

const CohortPage = lazy(() => import("./modules/Cohort/cohortPage"));
const Login = lazy(() => import("./modules/Auth/pages/Login"));

// Creates a wrapper for routes that provides query param context to all children
const RouteWithQueryParams = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <QueryParamsProvider>
          <Component {...props} />
        </QueryParamsProvider>
      )}
    />
  );
};

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const location = useLocation();
  const history = useHistory();

  // Handle one-time notifications like payment success
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("session_id")) {
      swal.fire(
        "Payment Successful",
        "Please, check your email and follow the instructions stated in your email to complete your registration process.",
        "success"
      );

      // Remove only the session_id param without affecting other query params
      params.delete("session_id");
      const newSearch = params.toString();
      const newPath = location.pathname + (newSearch ? `?${newSearch}` : "");

      history.replace(newPath);
    }
  }, [location.search, history]);

  return (
    <Switch>
      {!isAuthorized ? (
        <RouteWithQueryParams
          path="/auth/login/:status?/:token?/:refresh_token?/:user?"
          component={Login}
        />
      ) : (
        <Redirect from="/auth" to="/admin" />
      )}

      <RouteWithQueryParams path="/logout" component={Logout} />
      <RouteWithQueryParams path="/error" component={ErrorPage} />

      <Redirect
        exact
        from="/"
        to={{
          pathname: `/subscription`,
          search: location.search, // This preserves the query parameters
        }}
      />
      <Redirect exact from="/early-bird" to="/cohort" />

      <RouteWithQueryParams
        exact
        path="/subscription"
        component={SubscriptionPage}
      />
      <RouteWithQueryParams path="/special-programs" component={SpecialPage} />
      <RouteWithQueryParams path="/associate" component={Pages} />

      <RouteWithQueryParams path="/cohort" component={CohortPage} />

      {!isAuthorized ? (
        <Redirect to="/auth/login" />
      ) : (
        <RouteWithQueryParams exact path="/admin" component={MyPages} />
      )}

      <Redirect to="/error" />
    </Switch>
  );
}
