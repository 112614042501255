import React, { useEffect } from "react";
import TermsMessage from "./TermsMessage";

function TermsCondition({
  accepted,
  formik,
  priceData,
  setAccepted,
  getInputClasses,
  cLocation,
  countries,
  states,
  amountToPay,
  couponValue,
  verifyCouponCode,
  price,
  page,
  coup,
  selectedState,
  loading,
  getCouponCode,
}) {
  const couponCode = getCouponCode();
  useEffect(() => {
    if (couponCode && price) {
      formik.setFieldValue("couponCode", couponCode);
      document.getElementById("couponCode11").click();
    }
  }, [couponCode, price]);

  return (
    <>
      <div
        className="modal fade"
        id="formModal"
        tabIndex="-1"
        aria-labelledby="formModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <>
              <div className="modal-header">
                <h4 className="modal-title" id="formModalLabel">
                  User Details
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  {" "}
                  <div className="row">
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${getInputClasses(
                          "firstName"
                        )}`}
                        id="validationCustom01"
                        placeholder="First Name"
                        required
                        {...formik.getFieldProps("firstName")}
                      />
                      <div className="invalid-feedback">
                        {formik.errors["firstName"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${getInputClasses(
                          "lastName"
                        )}`}
                        id="validationCustom01"
                        placeholder="Last Name"
                        required
                        {...formik.getFieldProps("lastName")}
                      />
                      <div className="invalid-feedback">
                        {formik.errors["lastName"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${getInputClasses("email")}`}
                        id="validationCustom01"
                        placeholder="Email"
                        required
                        {...formik.getFieldProps("email")}
                      />
                      <div className="invalid-feedback">
                        {formik.errors["email"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Confirm Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${getInputClasses(
                          "confirm_email"
                        )}`}
                        id="validationCustom01"
                        placeholder="Confirm Email"
                        required
                        {...formik.getFieldProps("confirm_email")}
                      />
                      <div className="invalid-feedback">
                        {formik.errors["confirm_email"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Country<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        {...formik.getFieldProps("country")}
                        required
                        disabled={cLocation === "canada"}
                      >
                        <option>-- Select One --</option>
                        {countries.map((country, key) => (
                          <option key={key} value={country.value}>
                            {country.label}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        {formik.errors["country"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Province<span className="text-danger">*</span>
                      </label>
                      {states && states.length ? (
                        <select
                          className={`form-select ${getInputClasses(
                            "province"
                          )}`}
                          id="validationCustom01"
                          placeholder="Province"
                          {...formik.getFieldProps("province")}
                          required
                        >
                          <option value="">-- Select Province --</option>
                          {states.map((state) => (
                            <option key={state.state} value={state.state}>
                              {state.state}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className={`form-control ${getInputClasses(
                            "province"
                          )}`}
                          id="validationCustom01"
                          placeholder="Province"
                          {...formik.getFieldProps("province")}
                          required
                        />
                      )}
                      <div className="invalid-feedback">
                        {formik.errors["province"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        City<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${getInputClasses("city")}`}
                        id="validationCustom01"
                        placeholder="City"
                        {...formik.getFieldProps("city")}
                        required
                      />
                      <div className="invalid-feedback">
                        {formik.errors["city"]}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${getInputClasses(
                          "phoneNumber"
                        )}`}
                        id="validationCustom01"
                        placeholder="Phone Number"
                        {...formik.getFieldProps("phoneNumber")}
                        required
                      />
                      <div className="invalid-feedback">
                        {formik.errors["phoneNumber"]}
                      </div>
                    </div>
                  </div>
                  {amountToPay > 0 && (
                    <div className="row">
                      <center>
                        <div className="mb-3 col-md-8 col-12">
                          <label
                            htmlFor="couponCode"
                            className="form-label font-weight-bolder"
                          >
                            <h4>Coupon Code</h4>
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              className={`form-control ${getInputClasses(
                                "couponCode"
                              )}`}
                              id="validationCustom01"
                              placeholder="Enter Your Coupon Code"
                              required
                              {...formik.getFieldProps("couponCode")}
                              readOnly={couponValue}
                            />
                            {!couponValue ? (
                              <button
                                type="button"
                                id="couponCode11"
                                className="btn btn-secondary ml-3"
                                onClick={() => verifyCouponCode(price, true)}
                                disabled={couponValue}
                              >
                                Apply
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="invalid-feedback">
                            {formik.errors["couponCode"]}
                          </div>
                          <div className="mt-4">
                            <div>
                              Currency:{" "}
                              <b>
                                {formik?.values?.country === "CA"
                                  ? "CAD"
                                  : "USD"}
                              </b>
                            </div>
                            <div>
                              Gross Total:{" "}
                              <b>
                                {Number(price)?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: page?.currency || "USD",
                                  minimumFractionDigits: 0,
                                })}
                              </b>
                            </div>
                            <div>
                              Coupon Value(
                              {coup?.coupon_value
                                ? coup?.coupon_type === "fixed"
                                  ? coup?.coupon_value
                                  : coup?.coupon_value + "%"
                                : ""}
                              ):{" "}
                              <b>
                                {Number(couponValue || 0)?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                  }
                                )}
                              </b>
                            </div>
                            {selectedState ? (
                              <div>
                                {selectedState.name} :{" "}
                                {selectedState.percentage}%
                                <br />
                                {!selectedState.inclusive ? (
                                  <>
                                    Total to Pay:
                                    {(
                                      Number(amountToPay) +
                                      (selectedState.percentage *
                                        Number(amountToPay)) /
                                        100
                                    )?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: page?.currency || "USD",
                                      minimumFractionDigits: 0,
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <div>
                                Total to Pay:{" "}
                                <b>
                                  {Number(amountToPay)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: page?.currency || "USD",
                                      minimumFractionDigits: 0,
                                    }
                                  )}
                                </b>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 text-center">
                          <img
                            onClick={formik.handleSubmit}
                            style={{
                              width: "15rem",
                              cursor: "pointer",
                              opacity: loading ? 0.5 : 1,
                            }}
                            alt="Stripe"
                            src="/assets/images/card.jpeg"
                          ></img>
                          <br />
                          Pay with Stripe
                        </div>
                      </center>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  id="closeFormModal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={formik.handleSubmit}
                >
                  {amountToPay > 0 ? "Pay Now" : "Submit"}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsCondition;
