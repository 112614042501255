import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  ALL_SPECIAL_DEFAULT_PRICE,
  ONE_SPECIAL_DEFAULT_PRICE,
} from "../utils/functions";

function OptionsModal({
  showOptionsModal,
  setSell,
  setAccepted,
  referral,
  sell,
  setPrice,
  setAmountToPay,
  amountToPay,
  priceData,
  verifyCouponCode,
  setPriceData,
  formik,
  setCouponValue,
  couponValue,
  hideOptionModal,
  modalCleanup,
  paymentType,
  setPaymentType,
  oneSpecialProgramDefaultPrice,
  allSpecialProgramDefaultPrice,
  setAllSpecialProgramDefaultPrice,
  setOneSpecialProgramDefaultPrice,
  defCurrency,
}) {
  const calculatePrice = (basePrice, type) => {
    let price = Number(basePrice || 0);
    switch (paymentType) {
      case "half":
        let new_price = price > 1 ? (price / 2).toFixed(0) : price.toFixed(0);
        type === "all"
          ? setAllSpecialProgramDefaultPrice(new_price)
          : setOneSpecialProgramDefaultPrice(new_price);
        return new_price;
      case "four":
        let new_price1 = price > 1 ? (price / 4).toFixed(0) : price.toFixed(0);
        type === "all"
          ? setAllSpecialProgramDefaultPrice(new_price1)
          : setOneSpecialProgramDefaultPrice(new_price1);
        return new_price1;
      case "full":
        type === "all"
          ? setAllSpecialProgramDefaultPrice(price.toFixed(0))
          : setOneSpecialProgramDefaultPrice(price.toFixed(0));
        return price.toFixed(0);
      default:
        return price.toFixed(0);
    }
  };
  useEffect(() => {
    if (sell) {
      let pp =
        Number(sell) === 1
          ? oneSpecialProgramDefaultPrice
          : allSpecialProgramDefaultPrice;
      setPrice(pp);
      setAmountToPay(
        referral && amountToPay && priceData === "price" + sell
          ? amountToPay
          : pp
      );
      setPriceData("price" + sell);
      if (formik.values.couponCode) verifyCouponCode(pp);
      setCouponValue(
        referral && couponValue && priceData === "price" + sell
          ? couponValue
          : 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sell, paymentType]);

  return (
    <>
      <Modal
        show={showOptionsModal}
        onHide={modalCleanup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className="bg-light"
      >
        <Modal.Body>
          <section className="py-lg-4 py-xxl-4 py-4 bg-light">
            <div className="container">
              <div className="row">
                <div className="text-center mb-lg-4 mb-3">
                  <h2 className="fw-bold mb-3">
                    GET ALL SPECIAL PROGRAMS FOR COHORT 9
                  </h2>
                  <p className="mb-0 px-lg-10 px-5">
                    Save 93% by choosing ALL SPECIAL PROGRAMS! Get 12 months of
                    premium videos from all BLACKFORCE COHORT for the price of
                    2. Hurry—this limited-time offer won’t last!
                  </p>
                </div>
              </div>
              <center className="col-12 mx-auto h5">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="full"
                    checked={paymentType === "full"}
                    name="payment"
                    onChange={() => setPaymentType("full")}
                  />
                  <label className="form-check-label" htmlFor="full">
                    Full Payment
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="half"
                    checked={paymentType === "half"}
                    name="payment"
                    onChange={() => setPaymentType("half")}
                  />
                  <label className="form-check-label" htmlFor="half">
                    Two Installments
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="four"
                    checked={paymentType === "four"}
                    name="payment"
                    onChange={() => setPaymentType("four")}
                  />
                  <label className="form-check-label" htmlFor="four">
                    Four Installments
                  </label>
                </div>
              </center>
              <center className="col-12 mx-auto mb-3">
                {" "}
                {paymentType !== "full" && (
                  <div
                    className="mt-3 text-danger"
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14.6667px",
                      whiteSpaceCollapse: "preserve",
                    }}
                  >
                    <i className="fas fa-exclamation-circle me-2"></i>
                    Important: By selecting{" "}
                    {paymentType === "half"
                      ? "two installments, your account will be charged now and the balance will be deducted after 30 days."
                      : "four installments, your account will be charged now, with the balance split into three payments deducted every 15 days."}
                  </div>
                )}
              </center>

              <div className="row">
                <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
                  <div className="row">
                    <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                      {/*card*/}
                      <div className="card">
                        {/*card-body*/}
                        <div className="card-body p-5">
                          {/*content*/}
                          <span className="text-danger text-uppercase font-weight-bold">
                            ONE SPECIAL PROGRAM
                          </span>
                          <div className="d-flex align-items-center mt-5">
                            <h2 id="price1">
                              {Number(
                                calculatePrice(ONE_SPECIAL_DEFAULT_PRICE, "one")
                              )?.toLocaleString("en-US", {
                                style: "currency",
                                currency: defCurrency || "CAD",
                                minimumFractionDigits: 0,
                              })}
                              {paymentType !== "full" && (
                                <span style={{ fontSize: "14.6667px" }}>
                                  {" "}
                                  /{" "}
                                  {paymentType === "half"
                                    ? "2 payments"
                                    : "4 payments"}
                                </span>
                              )}
                            </h2>
                            {/* <span className="ms-1">(USD)</span> */}
                          </div>
                          <p className="mb-5">
                            You can ONLY take ONE special program offered in
                            this COHORT 9 and access only the previous recorded
                            videos for that Special Program.
                          </p>

                          <div className="mt-5 d-grid">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                hideOptionModal();
                                setAccepted(true);
                                setSell(1);
                              }}
                              // id="button1"
                              disabled=""
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                      {/*card*/}
                      <div className="card">
                        {/*card body*/}
                        <div className="card-body p-5">
                          {/*content*/}
                          <span className="text-uppercase text-danger font-weight-bold">
                            ALL SPECIAL PROGRAM
                          </span>
                          <div className="d-flex align-items-center mt-5">
                            <h2 id="price2">
                              {Number(
                                calculatePrice(ALL_SPECIAL_DEFAULT_PRICE, "all")
                              )?.toLocaleString("en-US", {
                                style: "currency",
                                currency: defCurrency || "CAD",
                                minimumFractionDigits: 0,
                              })}
                              {paymentType !== "full" && (
                                <span style={{ fontSize: "14.6667px" }}>
                                  {" "}
                                  /{" "}
                                  {paymentType === "half"
                                    ? "2 payments"
                                    : "4 payments"}
                                </span>
                              )}
                            </h2>
                          </div>
                          <p className="mb-0">
                            You can take all special programs we offer in this
                            COHORT 9 and access all previous cohort videos at
                            Blackforce.
                          </p>

                          <div className="mt-5 d-grid">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              // data-toggle="modal"
                              // data-target="#formModal"
                              onClick={() => {
                                // document.getElementById("pricing").click();
                                hideOptionModal();
                                setAccepted(true);
                                setSell(2);
                              }}
                              // id="button2"
                              disabled=""
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center mt-3">
                      <p
                        className="text-danger"
                        style={{
                          color: "rgb(0, 0, 0)",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "14.6667px",
                          whiteSpaceCollapse: "preserve",
                        }}
                      >
                        Payments and non refundable. Accounts are not
                        transferable. Note: Not all special programs are
                        guaranteed. Minimum of 10 Students are required for a
                        special program to hold.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OptionsModal;
