import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useQueryParams } from "../../contexts/QueryParamsContext";
import { Head } from "../components/Coach";
import { verifyCoupon, findTaxrates } from "./_redux/pagesCrud";
import swal from "sweetalert2";
import { useFormik } from "formik";
import { createLead } from "./_redux/pagesCrud";
import countries from "../../../data/countries.json";
import axios from "axios";
import Navigation from "./Nav";
import Home from "./Home";
import TermsCondition from "./TermsCondition";
import "../Associate/components/pricing.css";
import OptionsModal from "./components/OptionsModal";
import { toast } from "react-toastify";
import {
  init,
  specialProgramData,
  validationSchema,
} from "./components/formikSetup";
import SplashModal from "./components/SplashModal";
import {
  ALL_SPECIAL_DEFAULT_PRICE,
  ONE_SPECIAL_DEFAULT_PRICE,
} from "./utils/functions";

export default function Pricing() {
  const { queryParams } = useQueryParams();
  const [defCurrency, setDefCurrency] = useState("CAD");
  const [page, setPage] = useState();
  const [price, setPrice] = useState();
  const [amountToPay, setAmountToPay] = useState();
  const [couponValue, setCouponValue] = useState(0);
  const [priceData, setPriceData] = useState();
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [referral, setReferral] = useState(false);
  const [coup, setCoup] = useState();
  const [states, setStates] = useState();
  const [sell, setSell] = useState();
  const [professional, setProfessional] = useState([]);
  const [standard, setStandard] = useState([]);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [query_coupon, setQueryCoupon] = useState(null);
  const [paymentType, setPaymentType] = useState("full");
  const [oneSpecialProgramDefaultPrice, setOneSpecialProgramDefaultPrice] =
    useState(ONE_SPECIAL_DEFAULT_PRICE);
  const [allSpecialProgramDefaultPrice, setAllSpecialProgramDefaultPrice] =
    useState(ALL_SPECIAL_DEFAULT_PRICE);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: init,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.companyName = page.title;
      values.price = price;
      values.amount_to_pay = amountToPay;
      values.page_id = page.id;
      values.price_type = priceData;
      // values.half_price = twoPayments;
      values.paymentType = paymentType;
      values.isSpecial = true;
      values.defCurrency = defCurrency;
      if (selectedState && !selectedState.inclusive)
        values.tax_rate_id = selectedState.tax_rate_id;

      setLoading(true);
      createLead(values)
        .then((result) => {
          if (result.status !== 200) throw result;
          resetForm();
          modalCleanup();
          swal.fire(
            "",
            `Your registration was successful, an email will be sent to you shortly`,
            "success"
          );
          if (result.data.data?.payment_url)
            window.location.href = result.data.data?.payment_url;
          setLoading(false);
          setCouponValue(0);
        })
        .catch((e) => {
          swal.fire(
            "Oops",
            e.response?.data?.message || "Error uploading data",
            "error"
          );
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    if (formik.values.country)
      findTaxrates({
        filter: {
          status: true,
          country: formik.values.country,
          business_id: specialProgramData.business_id, //
        },
      })
        .then((res) => {
          setStates(res.data?.data?.entities);
          formik.values.province = "";
        })
        .catch((e) => setStates([]));

    if (formik.values.country) {
      if (formik.values.country === "CA") {
        setDefCurrency("CAD");
      } else {
        setDefCurrency("USD");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  const selectedState = useMemo(
    () => states?.find((s) => s.state === formik.values.province),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.province]
  );

  useEffect(() => {
    setPage(specialProgramData);
    if (specialProgramData.business_id) {
      axios
        .get(
          "https://apiteach.singlelogin.io/api/programs/?queryParams={%22filter%22:{%22status%22:%22draft%22,%22search_text%22:%22SPC9%22}}",
          { headers: { businessid: specialProgramData.business_id } }
        )
        .then((response) => {
          if (response.status === 200) {
            setStandard(response?.data?.data?.entities);
          }
        });
    }
  }, []);

  const verifyCouponCode = (pr, loud) => {
    if (formik.values.couponCode) {
      let coupon_value,
        new_price = pr || price,
        res;
      setLoading(true);
      verifyCoupon(
        {
          code: formik.values.couponCode,
          amount: new_price,
        },
        page.business_id
      )
        .then((result) => {
          if (result.status === 200) {
            loud && toast.success("Coupon applied!");
            res = result.data.data;
            coupon_value = res.coupon_value;
            if (res.coupon_type === "percentage")
              coupon_value = (res.coupon_value * new_price) / 100;
            new_price -= coupon_value;
            formik.setFieldValue("coupon_id", res.id);
            formik.setFieldValue("coupon_code", formik.values.couponCode);
          } else {
            // swal.fire("Oops", result.response.data.message, "error");
            toast.error(result.response.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          setCoup(res);
          setAmountToPay(new_price);
          setCouponValue(coupon_value);
        })
        .finally(() => {
          setCoup(res);
          setAmountToPay(new_price);
          setCouponValue(coupon_value);
          setLoading(false);
        });
    } else toast.warn("Please enter your referral code");
  };

  const modalCleanup = () => {
    setAccepted(false);
    setShowOptionsModal(false);
    setPaymentType("full");
    setAllSpecialProgramDefaultPrice(ALL_SPECIAL_DEFAULT_PRICE);
    setOneSpecialProgramDefaultPrice(ONE_SPECIAL_DEFAULT_PRICE);
    setPrice();
    setAmountToPay();
    setPriceData();
    // formik.resetForm();
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  Head();
  useEffect(() => {
    document.getElementById("splashScreenButton").click();
  }, []);

  useEffect(() => {
    if (queryParams.code) {
      setQueryCoupon(queryParams.code); // Update state with the code
      formik.setFieldValue("couponCode", queryParams.code); // Update formik value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.code]);

  const getCouponCode = useCallback(() => {
    return query_coupon;
  }, [query_coupon]); // couponCode in dependency array
  return (
    <>
      {referral && (
        <>
          <Navigation page={page} />

          {/* <Home /> */}

          <Home
            setShowOptionsModal={setShowOptionsModal}
            defCurrency={defCurrency}
          />
        </>
      )}

      <OptionsModal
        setPaymentType={setPaymentType}
        paymentType={paymentType}
        showOptionsModal={showOptionsModal}
        setSell={setSell}
        setAccepted={setAccepted}
        sell={sell}
        setPrice={setPrice}
        setAmountToPay={setAmountToPay}
        referral={referral}
        amountToPay={amountToPay}
        priceData={priceData}
        setPriceData={setPriceData}
        couponValue={couponValue}
        setCouponValue={setCouponValue}
        formik={formik}
        verifyCouponCode={verifyCouponCode}
        modalCleanup={modalCleanup}
        oneSpecialProgramDefaultPrice={oneSpecialProgramDefaultPrice}
        allSpecialProgramDefaultPrice={allSpecialProgramDefaultPrice}
        setAllSpecialProgramDefaultPrice={setAllSpecialProgramDefaultPrice}
        setOneSpecialProgramDefaultPrice={setOneSpecialProgramDefaultPrice}
        hideOptionModal={() => setShowOptionsModal(false)}
        defCurrency={defCurrency}
      />

      <TermsCondition
        accepted={accepted}
        formik={formik}
        priceData={priceData}
        setAccepted={setAccepted}
        getInputClasses={getInputClasses}
        // cLocation={cLocation}
        modalCleanup={modalCleanup}
        countries={countries}
        states={states}
        amountToPay={amountToPay}
        standard={standard}
        professional={professional}
        couponValue={couponValue}
        verifyCouponCode={verifyCouponCode}
        price={price}
        page={page}
        coup={coup}
        selectedState={selectedState}
        loading={loading}
        defCurrency={defCurrency}
        getCouponCode={getCouponCode}
      />
      <SplashModal formik={formik} setReferral={setReferral} />
    </>
  );
}
